import axios from "axios";
import { createContext, FC, useCallback, useEffect, useState } from "react";

interface IAuthContext {
  username: string;
  password: string;
  token: string;
  error: string;
  isProcessing: boolean;
  setUsername: (username: string) => void;
  setPassword: (password: string) => void;
  onAuthenticate: null | (() => Promise<boolean>);
  onUpdateToken: (token: string) => void;
  onReset: () => void;
  onLogout: () => void;
}
const authContextDefault: IAuthContext = {
  username: "",
  password: "",
  token: "",
  error: "",
  isProcessing: false,
  setUsername: (username: string) => {},
  setPassword: (password: string) => {},
  onAuthenticate: null,
  onUpdateToken: (token: string) => {},
  onReset: () => {},
  onLogout: () => {},
};
export const AuthContext = createContext(authContextDefault);
export const AuthContextProvider: FC<any> = ({ children }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [error, setError] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    const storedToken = localStorage.getItem("NAGA_ADMIN:token");
    if (storedToken) {
      setToken(storedToken);
    }
  }, []);

  const onAuthenticate = useCallback(async () => {
    setIsProcessing(true);
    setError("");
    try {
      const request = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/authorization/login`,
        {
          username,
          password,
        },
      );

      if (request.data.error) {
        setError(`ไม่สามารถเข้าระบบได้: ${request.data.error}`);
        setIsProcessing(false);
        return false;
      }
      console.log("onAuthenticate",request)

      localStorage.setItem("NAGA_ADMIN:token", request.data.data);
      setToken(request.data.data);
      setError("");
      setIsProcessing(false);
      return true;
    } catch (error) {
      setError("ไม่สามารถเข้าระบบได้");
      setIsProcessing(false);
      return false;
    }
  }, [username, password]);

  const onUpdateToken = useCallback(async (updateToken: string) => {
    localStorage.setItem("NAGA_ADMIN:token", updateToken);
    setToken(updateToken);
  }, []);

  const onReset = useCallback(async () => {
    setToken("");
    setUsername("");
    setPassword("");
    setError("");
    setIsProcessing(false);
  }, []);

  const onLogout = () => {
    setUsername("");
    setPassword("");
    localStorage.removeItem("NAGA_ADMIN:token");
    return false;
  };

  return (
    <AuthContext.Provider
      value={{
        username,
        password,
        token,
        error,
        isProcessing,
        setUsername: (value) => setUsername(value),
        setPassword: (value) => setPassword(value),
        onAuthenticate,
        onUpdateToken,
        onReset,
        onLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// import axios from "axios";
// import { createContext, FC, useCallback, useEffect, useState } from "react";

// interface IAuthContext {
//   username: string;
//   password: string;
//   token: string;
//   error: string;
//   // isSuperadmin: boolean;
//   isProcessing: boolean;
//   setUsername: (username: string) => void;
//   setPassword: (password: string) => void;
//   // setIsSuperadmin: (isSuperadmin: boolean) => void;
//   onAuthenticate: null | (() => Promise<boolean>);
//   onReset: () => void;
//   onLogout: () => void;
// }

// const authContextDefault: IAuthContext = {
//   username: "",
//   password: "",
//   token: "",
//   error: "",
//   // isSuperadmin: false,
//   isProcessing: false,
//   setUsername: (username: string) => {},
//   setPassword: (password: string) => {},
//   // setIsSuperadmin: (isSuperadmin: boolean) => {},
//   onAuthenticate: null,
//   onReset: () => {},
//   onLogout: () => {},
// };

// export const AuthContext = createContext(authContextDefault);

// export const AuthContextProvider: FC<any> = ({ children }) => {
//   const [username, setUsername] = useState(authContextDefault.username);
//   const [password, setPassword] = useState(authContextDefault.password);
//   const [token, setToken] = useState(authContextDefault.token);
//   const [error, setError] = useState(authContextDefault.error);
//   // const [isSuperadmin, setIsSuperadmin] = useState(authContextDefault.isSuperadmin);
//   const [isProcessing, setIsProcessing] = useState(authContextDefault.isProcessing);

//   useEffect(() => {
//     const storedToken = localStorage.getItem('NAGA_ADMIN:token');
//     if (storedToken) {
//       setToken(storedToken);
//     }
//   }, []);

//   const onAuthenticate = useCallback(async () => {
//     setIsProcessing(true);
//     setError("");
//     try {
//       const request = await axios.post(
//         `${process.env.REACT_APP_API_URL}/api/v1/authorization/login`,
//         {
//           username,
//           password,
//         }
//       );

//       console.log('API Response:', request.data);

//       if (request.data.error) {
//         setError(`ไม่สามารถเข้าระบบได้: ${request.data.error}`);
//         setIsProcessing(false);
//         return false;
//       }

//       const token = request.data.data; // สมมติว่า token อยู่ที่ request.data.data
//       localStorage.setItem("NAGA_ADMIN:token", token);
//       setToken(token);
//       setError("");
//       // หากมีข้อมูล isSuperadmin ใน response คุณสามารถตั้งค่าได้เช่นนี้:
//       // setIsSuperadmin(request.data.isSuperadmin || false);
//       setIsProcessing(false);
//       return true;
//     } catch (error) {
//       console.error('Login error:', error);
//       setError("ไม่สามารถเข้าระบบได้");
//       setIsProcessing(false);
//       return false;
//     }
//   }, [username, password]);

//   const onReset = useCallback(async () => {
//     setToken("");
//     setUsername("");
//     setPassword("");
//     setError("");
//     setIsProcessing(false);
//     // setIsSuperadmin(false);
//   }, []);

//   const onLogout = () => {
//     setUsername("");
//     setPassword("");
//     setToken("");
//     // setIsSuperadmin(false);
//     localStorage.removeItem('NAGA_ADMIN:token');
//     return false;
//   };

//   return (
//     <AuthContext.Provider
//       value={{
//         username,
//         password,
//         token,
//         error,
//         // isSuperadmin,
//         isProcessing,
//         setUsername,
//         setPassword,
//         // setIsSuperadmin,
//         onAuthenticate,
//         onReset,
//         onLogout,
//       }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };
