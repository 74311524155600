import { lazy } from "react";
import { AuthContextProvider } from "../contexts/AuthContext";
import { ScreenLoader } from "../utils/ScreenLoader";
import { AuthGuard } from "../utils/AuthGuard";
import { Outlet } from "react-router";
import { elements } from "chart.js";



const EmployeeAdd = ScreenLoader(
  lazy(() => import("../screens/employee-screen/EmployeeAdd")),
);
const EmployeeManagement = ScreenLoader(
  lazy(() => import("../screens/employee-screen/EmployeeManagement")),
);
const AdminLog = ScreenLoader(
  lazy(() => import("../screens/employee-screen/AdminLog")),
);

const RoleManagement = ScreenLoader (
  lazy(() => import("../screens/employee-screen/RoleManagement")),
);

const RoleAdd = ScreenLoader (
  lazy(() => import("../screens/employee-screen/RoleAdd")),
);
const RoleEdit = ScreenLoader (
  lazy(() => import("../screens/employee-screen/RoleEdit")),
);


export const EmployeeRoute = () => ({
  path: "employee",
  element: (
    <AuthContextProvider>
      <AuthGuard>
        <Outlet />
      </AuthGuard>
    </AuthContextProvider>
  ),
  children: [
    { path: "employee-add", element: <EmployeeAdd /> },
    { path: "employee-management", element: <EmployeeManagement /> },
    { path: "admin-log", element: <AdminLog /> },
    { path: "role-add", element: <RoleAdd /> },
    { path: "role-edit/:id", element: <RoleEdit /> },
    { path: "role-management", element: <RoleManagement /> },
  ],
});
